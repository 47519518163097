export default {
  isCourse(type) {
    const types = ["gamecraft", "vytvor-si-hru", "gamisfera-licencia", "moja-prva-hra", "gamecraft-na-skolach", "gamecraft-na-skolach-60min"]

    return types.includes(type)
  },

  isLevelUp(type) {
    const types = ["levelup-your-skills", "levelup", "levelup-1rocnik", "summer-game-dev-academy"]

    return types.includes(type)
  },

  isOnlineCourse(type) {
    const types = ["gamecraft", "vytvor-si-hru", "gamisfera-licencia", "moja-prva-hra", "levelup-your-skills", "gamecraft-na-skolach", "gamecraft-na-skolach-60min"]

    return types.includes(type)
  },

  isCamp(type) {
    const types = ["gamecamp"]

    return types.includes(type)
  },

  isAcademy(type){
    const types = ["summer-game-dev-academy"]

    return types.includes(type)
  },
}
