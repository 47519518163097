<template>
  <div class="container d-flex flex-column text-center mt-5" >
    <h1>Ďakujeme za Vašu objednávku</h1>
    <p v-if="isCourse">
      Faktúra Vám príde e-mailom v najbližších dňoch.
      Bližšie informácie o chode kurzu Vám pošleme tesne pred nástupom na kurz.
    </p>
    <p v-else-if="isLevelUp">
      Faktúra Vám príde e-mailom v najbližších dňoch. 
      Bližšie informácie Vám zašleme čoskoro.
    </p>
    <p v-else>
      Faktúra Vám príde e-mailom v najbližších dňoch. 
      Bližšie informácie o tábore Vám pošleme týždeň pred nástupom.
    </p>
  </div>
</template>
<script>
import productUtils from "@/plugins/app/_utils/product.util.js"

export default {
  created() {
    this._trackDataLayerEvent()
    this.$store.commit('order/reseted', true)
  },

  computed: {
    type() {
      return this.$route.query.type
    },

    isCourse(){return productUtils.isCourse(this.type)},
    isLevelUp(){return productUtils.isLevelUp(this.type)},
  },

  methods: {
    _trackDataLayerEvent() {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'purchase',
        'pageTitle': 'Ďakujeme za Vašu objednávku'
      })
    }
  }
}
</script>